<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card class="primary--border" outlined>
          <div class="profile-tab-container">
            <div class="edit-profile-button">
              <a
                v-if="
                  student.updateGeneral === false && $auth.can('student-update')
                "
                href=""
                style="color:white"
                @click.prevent="
                  $route.name !== 'std-profile'
                    ? $router.push({ name: 'std-profile' })
                    : '',
                    SET_GENERAL_UPDATE(true)
                "
              >
                <v-icon color="white" small>edit</v-icon>
                <span>Edit</span>
              </a>
              <a
                v-else-if="$auth.can('student-update')"
                href=""
                style="color:white"
                @click.prevent="SET_GENERAL_UPDATE(false)"
              >
                <v-icon color="white" small>edit</v-icon>
                <span>Cancel</span>
              </a>
              <a
                v-if="
                  student.deleteAlert === false && $auth.can('student-delete')
                "
                style="color:white;"
                href=""
                @click.prevent="
                  $route.name !== 'std-profile'
                    ? $router.push({ name: 'std-profile' })
                    : '',
                    SET_DELETE_ALERT(true)
                "
              >
                <v-icon small color="white">delete</v-icon>
                <span>Delete</span>
              </a>
              <a
                v-if="
                  student.deleteAlert === true && $auth.can('student-delete')
                "
                style="color:white;"
                href=""
                @click.prevent="
                  $route.name !== 'std-profile'
                    ? $router.push({ name: 'std-profile' })
                    : '',
                    SET_DELETE_ALERT(false)
                "
              >
                <v-icon small color="white">delete</v-icon>
                <span>Cancel</span>
              </a>
            </div>
            <div class="profile-intro">
              <h2>
                {{ this.student.data.name }}
                <span>({{ this.student.data.enroll_code }})</span>
              </h2>
              <h4>
                Grade {{ this.student.data.grade }} /
                {{ this.student.data.section }} / Roll No
                {{ this.student.data.roll }}
              </h4>
            </div>

            <div class="tab-content">
              <div class="t-container">
                <div
                  @click="$auth.can('student-update') ? onPickFile() : ''"
                  class="profile-image"
                >
                  <div
                    :class="
                      'image ' +
                        (cropperImg.uploading === true ? 'uploading' : '')
                    "
                  >
                    <h3 v-if="cropperImg.uploading">Uploading...</h3>
                    <p v-if="cropperImg.uploading">Please wait...</p>
                    <img
                      v-if="!cropperImg.uploading"
                      :src="student.data.profile_image || $auth.company('logo')"
                      alt="User Image"
                    />
                    <input
                      type="file"
                      style="display: none"
                      ref="image"
                      accept="image/*"
                      @change="onFilePicked"
                    />
                  </div>
                </div>
                <div class="t_hold" style="width:100%">
                  <template>
                    <v-bottom-navigation
                      style="box-shadow:none;height:54px"
                      :value="activeBtn"
                      color="primary lighten-1 elevation-0"
                    >
                      <v-btn
                        @click="$router.push({ name: 'std-profile' })"
                        value="general"
                      >
                        <span>Generals</span>
                        <v-icon>history</v-icon>
                      </v-btn>

                      <v-btn
                        @click="$router.push({ name: 'std-enroll' })"
                        value="enroll"
                      >
                        <span>Enroll</span>
                        <v-icon>favorite</v-icon>
                      </v-btn>

                      <v-btn
                        @click="$router.push({ name: 'std-transport' })"
                        value="transport"
                      >
                        <span>Address & Transport</span>
                        <v-icon>directions_bus</v-icon>
                      </v-btn>
                    </v-bottom-navigation>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-flex>

      <div class="profile-contents">
        <router-view></router-view>
      </div>
    </v-layout>

    <v-dialog v-model="cropperImg.cropDialog" persistent max-width="600">
      <v-card>
        <vue-cropper
          ref="cropper"
          :guides="true"
          :view-mode="2"
          drag-mode="crop"
          :auto-crop-area="0.5"
          :min-container-width="100"
          :min-container-height="100"
          :background="false"
          :min-crop-box-width="200"
          :max-crop-box-width="200"
          :min-crop-box-height="200"
          :max-crop-box-height="200"
          :aspect-ratio="1"
          :rotatable="true"
          :src="cropperImg.imgSrc"
          alt="Source Image"
          :img-style="{ width: '500px', height: '500px' }"
        >
        </vue-cropper>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" @click="cropperImg.cropDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="green darken-1" @click="cropImage">Crop</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import Form from "@/library/Form";
import { mapState, mapActions, mapMutations } from "vuex";

import ProfileHome from "./pages/home";
import ProfileEnroll from "./pages/enroll";
import ProfileTransport from "./pages/transport";
import VueCropper from "vue-cropperjs";

export default {
  // ProfileHome, ProfileEnroll, ProfileTransport,
  components: { VueCropper },
  data: () => ({
    cropperImg: {
      cropDialog: false,
      imgSrc: "",
      cropImg: "",
      uploadCropped: {},
      name: null,
      uploading: false,
    },
    form: new Form({}, "/api/student"),
    uploadProfile: new Form({ image: "" }, "/api/student"),
    transport: {},
    activeBtn: "general",
    direction: "top",
    fab: false,
    fling: false,
    hover: false,
    tabs: null,
    top: false,
    right: true,
    bottom: true,
    left: false,
    transition: "slide-y-reverse-transition",
    fetch_student_record: true,
  }),
  watch: {
    batch: {
      handler() {
        if (this.fetch_student_record === true) this.get();
      },
    },
  },
  mounted() {
    if (this.fetch_student_record == true) this.get();

    switch (this.$route.name) {
      case "std-enroll":
        this.activeBtn = "enroll";
        break;
      case "std-profile":
        this.activeBtn = "general";
        break;
      case "std-transport":
        this.activeBtn = "transport";
        break;
    }
    this.SET_DELETE_ALERT(false);
    this.SET_GENERAL_UPDATE(false);
    this.SET_GUARDIAN_UPDATE(false);
  },
  computed: {
    ...mapState(["student", "batch"]),
  },
  methods: {
    ...mapActions(["setStudent", "getStudentInfo"]),
    ...mapMutations([
      "SET_GENERAL_UPDATE",
      "SET_DELETE_ALERT",
      "SET_GUARDIAN_UPDATE",
    ]),
    updateTab(index) {
      this.activeBtn = index || 1;
    },
    get() {
      this.fetch_student_record = false;
      this.getStudentInfo("/api/student/" + this.$route.params.id)
        .then((res) => {
          // console.log("herello.");
          this.fetch_student_record = true;
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            this.$events.fire("notification", {
              message: "Students not found",
              status: "error",
            });
            this.$router.push({
              name: "search-students",
            });
          }
        });
    },

    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      const canvas = this.$refs.cropper.getCroppedCanvas();
      this.cropperImg.cropImg = canvas.toDataURL();
      let vm = this;
      canvas.toBlob(
        function(blob) {
          blob.name = "image";
          vm.cropperImg.uploadCropped = blob;
          vm.cropperImg.cropDialog = false;

          //upload to server.
          if (Object.keys(vm.cropperImg.uploadCropped).length > 0) {
            let formData = new FormData();
            vm.uploadProfile.image = vm.cropperImg.uploadCropped;
            vm.uploadProfile.endpoint =
              "/api/student/" + vm.student.data.id + "/profile";
            vm.uploadProfile.fireFetch = false;
            vm.cropperImg.uploading = true;
            vm.uploadProfile
              .store()
              .then(() => {
                vm.get();
              })
              .finally(() => {
                vm.cropperImg.uploading = false;
              });
          }
        },
        "image/jpeg",
        0.3
      );
    },

    onPickFile() {
      this.$refs.image.click();
    },
    onFilePicked(e) {
      const file = e.target.files[0];
      if (!file.type.includes("image/")) {
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropperImg.imgSrc = event.target.result;
          this.cropperImg.name = file.name;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      this.cropperImg.cropDialog = true;
    },
  },
};
</script>

<style lang="scss">
.user_add_form {
  .v-chip,
  .v-chip .v-chip__content {
    border-radius: 2px;
    color: white;
  }
}

.v-item-group.v-bottom-nav {
  box-shadow: none;
}

.v-list > div:hover {
  cursor: pointer;
  background-color: #eee;
}

.update-form-profile {
  h2 {
    color: #777;
    margin-bottom: 8px;
  }
}
</style>
